<template>
  <div>
    <el-table
      :data="data"
      style="width: 100%"
      class="vue-data-table-default"
      border
      stripe
    >
      <el-table-column
        :width="250"
        v-for="(step, index) of columns"
        :label="step.label"
        :key="step.key + index"
        :prop="step.key"
        sortable="custom"
      >
        <template slot-scope="scope">
          {{ scope.row[step.key].name || scope.row[step.key].value }}
        </template>
      </el-table-column>
      <el-table-column
        prop="action"
        label="Actions"
        class="action"
        min-width="170"
        fixed="right"
      >
        <template slot-scope="scope">
          <ul class="action-buttons">
            <li v-for="(action, index) in actions" :key="'action_' + index">
              <button class="btn btn-light btn-sm m-lr-1" type="button">
                <span
                  class="text-muted"
                  title="Add"
                  @click="executeAction(scope.row, action)"
                  ><i class="fa fa-plus-circle"></i
                ></span>
              </button>
            </li>
          </ul>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import TemplateBuilderhelper from "@/mixins/templateBuilderHelper.js";
import NavigationHelper from "@/mixins/navigationHelper";
import moment from "moment";
export default {
  mixins: [TemplateBuilderhelper, NavigationHelper],
  props: {
    data: Array,
    columns: Array,
    actions: Array,
    getAllEntities: Array,
    getAllCompanyTemplatesData: Object,
  },
  mounted() {
    this.$store.commit(
      "templates/setSuggestionFormData",
      null,
      {
        root: true,
      }
    );
  },
  methods: {
    generateSuggestionForm(entity, otherInfo, row) {
      let form = {};
      (entity.templates || []).forEach((temp) => {
        let template = (this.getAllCompanyTemplatesData?.data || []).find(
          (e) => e._id == temp.template_id
        );
        if (template?.sections?.[0]?.fields && otherInfo) {
          let tempObj = {};
          template.sections[0].fields.map((field) => {
            let key =
              field && field.key.includes("#")
                ? field.key.split("#")[1]
                : field.key;
            if (field.inputType == "ENTITY") {
              if (otherInfo.entity_id == field.entity_id) {
                tempObj[key] = row._id;
              }
            } else if (field.input_type == "DATE") {
              if (
                otherInfo.other_entity_date.includes("#") &&
                otherInfo.other_entity_date.split("#")[1] == key
              ) {
                tempObj[key] = moment(row.slotDate.value).startOf('day').toISOString();
              }
            }
          });
          form[temp.template_id] = tempObj;
        }
      });
      return form;
    },
    executeAction(row, action) {
      if (action.type == "ADD") {
        let suggestionForm = this.generateSuggestionForm(
          this.getAllEntities.find((e) => action.entity_id == e._id),
          action.other_info,
          row
        );
        this.$store.commit("templates/setSuggestionFormData", suggestionForm, {
          root: true,
        });
        this.openEntityDataScreen(action.entity_id, "", "", "fromCustom");
      }
    },
  },
};
</script>
